<template>
  <div class="changePassword-wrapper">
<!--    <v-row class="hidden-md-and-up" style="height:75px">-->
<!--      <v-col cols="12" class="password-mobile-header text-center">-->
<!--        {{ $t(`label.changePassword`) }}-->
<!--      </v-col>-->
<!--    </v-row>-->
    <v-card class="desktop_card">
      <v-card-title class="hidden-sm-and-down">
        <label class="--v-primary-base text-left text-capitalize">{{ $t(`label.changePassword`) }}</label>
      </v-card-title>
      <v-card class="profile-detail-card" elevation="0">
        <v-form ref="changePasswordForm">
          <v-row no-gutters align="end" class="px-8 pt-4">
            <v-col lg=4 md=5>
              <app-form-field v-model.trim="changePasswordObj.oldPassword" :enabledLabel=true :label="$t(`field.currentPassword`)" :placeholder="$t(`fieldHint.fillUpPwdHere`)" :rules="validator.passwordRules()" type="password"></app-form-field>
            </v-col>
          </v-row>
          <v-row no-gutters align="end" class="px-8">
            <v-col lg=4 md=5>
              <app-form-field v-model.trim="changePasswordObj.newPassword" :enabledLabel=true :tooltip="$t(`fieldHint.password`)" :label="$t(`field.newPassword`)" :placeholder="$t(`fieldHint.fillUpPwdHere`)" :rules="validator.passwordRules()" type="password"></app-form-field>
            </v-col>
          </v-row>
          <v-row no-gutters align="end" class="px-8">
            <v-col lg=4 md=5 class="mb-4">
              <app-form-field v-model.trim="changePasswordObj.confirmNewPassword" :enabledLabel=true :label="$t(`field.confirmNewPassword`)" :placeholder="$t(`fieldHint.fillUpPwdHere`)" :rules="validator.confirmPasswordRules(changePasswordObj.newPassword, changePasswordObj.confirmNewPassword)"
                              type="password"></app-form-field>
              <app-button class="dialog-button submit-btn" :action="this.changePassword" :title="$t(`button.submit`)"></app-button>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-card>
  </div>

</template>

<script>
  import stingFormat from 'string-format'
  import {
    locale,
    formValidator,
    errorCodeHelper
  } from '@/util'
  import {
    MEMBER_CHANGE_PASSWORD,
    MEMBER_RESET_CHANGE_PASSWORD_STORE
  } from '@/store/member.module'
  export default {
    name: 'appChangePassword',
    mounted() {
      this.$refs.changePasswordForm.reset()
    },
    data: () => ({
      validator: formValidator,
      stingFormat: stingFormat,
      retainSignIn: false,
      changePasswordObj: {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      }
    }),
    computed: {
      changePasswordResponseComplete() {
        return this.$store.state.member.changePasswordResponse.complete
      }
    },
    watch: {
      changePasswordResponseComplete() {
        let response = this.$store.state.member.changePasswordResponse
        if (response.complete) {
          this.changePasswordResponseCompleteDialog(response)
          this.$store.dispatch(`${MEMBER_RESET_CHANGE_PASSWORD_STORE}`)
        }
      }
    },
    methods: {
      changePassword() {
        if (this.$refs.changePasswordForm.validate()) {
          let changePasswordObj = this.changePasswordObj
          this.$store.dispatch(`${MEMBER_CHANGE_PASSWORD}`, {
            changePasswordObj
          })
        }
      },
      changePasswordResponseCompleteDialog(response) {
        if (response.code != 0 && !response.success) {
            this.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(response.code))
        } else {
            this.$parent.openAlert(true, locale.getMessage(`message.memberPasswordUpdated`))
        }
      }
    }
  }
</script>

<style lang="scss">
.password-mobile-header{
  background-color: #000000;
  color: var(--v-newMobilePrimary-base);
  margin-top: auto;
  margin-bottom: auto;

}

@media (max-width: 959px){
  .changePassword-wrapper{
    height: 100% !important;
  }

  .submit-btn.buttonPrimary{
    background-color: var(--v-newMobilePrimary-base) !important;
    color:#FFFFFF;
  }
}
</style>
